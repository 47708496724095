import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentLicence
    ? _c(
        VContainer,
        { attrs: { fluid: "" } },
        [
          _c(
            VRow,
            [
              _c(
                VCol,
                { staticClass: "licence-data ma-3", attrs: { md: 3 } },
                [
                  _c(
                    VCard,
                    { staticClass: "md-auto", attrs: { tile: "" } },
                    [
                      _c(
                        VCardTitle,
                        { staticClass: "justify-center" },
                        [
                          _c(
                            VAvatar,
                            { staticClass: "mb-5", attrs: { size: "85" } },
                            [
                              _vm.currentLicence.productName ===
                                "Consteel Starter" ||
                              _vm.currentLicence.productName ===
                                "Consteel Starter with Falcon"
                                ? _c(VImg, {
                                    attrs: {
                                      contain: "",
                                      alt: "Consteel starter",
                                      src: require("@/assets/starter.svg")
                                    }
                                  })
                                : _vm.currentLicence.productName ===
                                    "Consteel Full" ||
                                  _vm.currentLicence.productName ===
                                    "Consteel Full with Falcon" ||
                                  _vm.currentLicence.productname === "Consteel"
                                ? _c(VImg, {
                                    attrs: {
                                      contain: "",
                                      alt: "Consteel",
                                      src: require("@/assets/cs.svg")
                                    }
                                  })
                                : _vm.currentLicence.productName === "csJoint"
                                ? _c("vimg", {
                                    attrs: {
                                      alt: "Consteel",
                                      src: "@/assets/joint.svg"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.inspectUser
                        ? _c(
                            VCardSubtitle,
                            { attrs: { align: "center" } },
                            [
                              _c(
                                VRow,
                                [
                                  _c(VCol, { attrs: { cols: 12 } }, [
                                    _c(
                                      "span",
                                      { staticClass: "white--text headline" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.capitalizeStr(
                                              _vm.currentLicence.type
                                            )
                                          ) + " licence"
                                        )
                                      ]
                                    )
                                  ]),
                                  _c(
                                    VCol,
                                    { attrs: { cols: 12 } },
                                    [
                                      _c(
                                        VChip,
                                        {
                                          attrs: {
                                            to: "/user/" + _vm.inspectUser.id,
                                            outlined: "",
                                            color: _vm.getColor(
                                              _vm.inspectUser.email
                                            )
                                          }
                                        },
                                        [
                                          _c(
                                            VIcon,
                                            { staticClass: "mr-3" },
                                            [
                                              _vm._v(
                                                " mdi-account-circle-outline "
                                              )
                                            ]
                                          ),
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.inspectUser.name) +
                                              " "
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(VDivider, { staticClass: "ml-15 mr-15" }),
                      _c(
                        VList,
                        [
                          _c(
                            VListItemGroup,
                            [
                              _c(
                                VListItem,
                                [
                                  _c(
                                    VListItemIcon,
                                    [
                                      _c(VIcon, [
                                        _vm._v("mdi-database-outline")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    VListItemContent,
                                    [
                                      _vm.currentLicence
                                        ? _c(VListItemTitle, [
                                            _vm._v(
                                              _vm._s(_vm.currentLicence.id)
                                            )
                                          ])
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    VListItemAction,
                                    [
                                      _vm.currentLicence
                                        ? _c(
                                            VBtn,
                                            {
                                              directives: [
                                                {
                                                  name: "clipboard",
                                                  rawName: "v-clipboard:copy",
                                                  value: _vm.currentLicence.id,
                                                  expression:
                                                    "currentLicence.id",
                                                  arg: "copy"
                                                }
                                              ],
                                              attrs: { icon: "" }
                                            },
                                            [
                                              _c(VIcon, [
                                                _vm._v("mdi-content-copy")
                                              ])
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                VListItem,
                                [
                                  _c(
                                    VListItemIcon,
                                    [_c(VIcon, [_vm._v("mdi-resistor")])],
                                    1
                                  ),
                                  _c(
                                    VListItemContent,
                                    [
                                      _c(
                                        VListItemTitle,
                                        [
                                          _vm.currentLicence.licenceStatus ===
                                          "pending"
                                            ? _c(
                                                VChip,
                                                {
                                                  attrs: {
                                                    outlined: "",
                                                    dark: "",
                                                    color: "orange"
                                                  }
                                                },
                                                [_vm._v("Pending")]
                                              )
                                            : _vm.currentLicence
                                                .licenceStatus === "declined"
                                            ? _c(
                                                VChip,
                                                {
                                                  attrs: {
                                                    outlined: "",
                                                    dark: "",
                                                    color: "error"
                                                  }
                                                },
                                                [_vm._v("Declined")]
                                              )
                                            : _vm.currentLicence
                                                .licenceStatus === "deactivated"
                                            ? _c(
                                                VChip,
                                                {
                                                  attrs: {
                                                    outlined: "",
                                                    dark: "",
                                                    color: "grey"
                                                  }
                                                },
                                                [_vm._v("Deactivated")]
                                              )
                                            : _vm.currentLicence
                                                .licenceStatus === "active"
                                            ? _c(
                                                VChip,
                                                {
                                                  attrs: {
                                                    outlined: "",
                                                    dark: "",
                                                    color: "green"
                                                  }
                                                },
                                                [_vm._v("Active")]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                VListItem,
                                [
                                  _c(
                                    VListItemIcon,
                                    [
                                      _c(VIcon, [
                                        _vm._v("mdi-label-outline")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    VListItemContent,
                                    [
                                      _c(VListItemTitle, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.capitalizeStr(
                                              _vm.currentLicence.type
                                            )
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                VListItem,
                                [
                                  _c(
                                    VListItemIcon,
                                    [
                                      _c(VIcon, [_vm._v("mdi-post-outline")])
                                    ],
                                    1
                                  ),
                                  _c(
                                    VListItemContent,
                                    [
                                      _c(VListItemTitle, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.capitalizeStr(
                                              _vm.currentLicence.productName
                                            )
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    VListItemAction,
                                    [
                                      _c(
                                        VDialog,
                                        {
                                          attrs: {
                                            "nudge-right": 40,
                                            transition: "scale-transition",
                                            "offset-y": "",
                                            width: "20%"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      VBtn,
                                                      _vm._g(
                                                        { attrs: { icon: "" } },
                                                        on
                                                      ),
                                                      [
                                                        _c(VIcon, [
                                                          _vm._v(
                                                            "mdi-pencil-outline"
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            3271518839
                                          ),
                                          model: {
                                            value: _vm.modifyProductDialg,
                                            callback: function($$v) {
                                              _vm.modifyProductDialg = $$v
                                            },
                                            expression: "modifyProductDialg"
                                          }
                                        },
                                        [
                                          _c(
                                            VCard,
                                            [
                                              _c(VAutocomplete, {
                                                staticClass: "ma-3",
                                                attrs: {
                                                  outlined: "",
                                                  dense: "",
                                                  items: [
                                                    "Consteel",
                                                    "Consteel Full",
                                                    "Consteel Full with Falcon",
                                                    "Consteel Starter",
                                                    "Consteel Starter with Falcon",
                                                    "csJoint"
                                                  ],
                                                  label: "Set product type",
                                                  value:
                                                    _vm.currentLicence
                                                      .productName
                                                },
                                                on: { input: _vm.modifyProduct }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                VListItem,
                                [
                                  _c(
                                    VListItemIcon,
                                    [
                                      _c(VIcon, [
                                        _vm._v("mdi-account-group-outline")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    VListItemContent,
                                    [
                                      _c(VListItemTitle, [
                                        _vm._v(
                                          _vm._s(_vm.inspectAccesses.length)
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                VListItem,
                                [
                                  _c(
                                    VListItemIcon,
                                    [
                                      _c(VIcon, [
                                        _vm._v("mdi-sofa-single-outline")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    VListItemContent,
                                    [
                                      _c(VListItemTitle, [
                                        _vm._v(_vm._s(_vm.inspectSeats.length))
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VExpansionPanels,
                        { attrs: { accordion: "" } },
                        [
                          _c(
                            VExpansionPanel,
                            [
                              _c(VExpansionPanelHeader, [
                                _vm._v(" Attachments "),
                                _vm.attachmentData.length
                                  ? _c(
                                      "span",
                                      { staticClass: "ml-10 green--text" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.attachmentData.length) +
                                            " files uploaded"
                                        )
                                      ]
                                    )
                                  : !_vm.attachmentData.length &&
                                    _vm.currentLicence.licenceStatus ===
                                      "pending" &&
                                    _vm.currentLicence.licenceStatus !== "trial"
                                  ? _c(
                                      "span",
                                      { staticClass: "ml-10 orange--text" },
                                      [_vm._v("There are no attachments")]
                                    )
                                  : _c(
                                      "span",
                                      { staticClass: "ml-10 grey--text" },
                                      [_vm._v("There are no attachments")]
                                    )
                              ]),
                              _c(
                                VExpansionPanelContent,
                                [
                                  _c(
                                    VList,
                                    _vm._l(_vm.attachmentData, function(data) {
                                      return _c(
                                        VListItem,
                                        {
                                          key: data.name,
                                          attrs: {
                                            href: data.url,
                                            target: "_blank"
                                          }
                                        },
                                        [
                                          _c(
                                            VListItemIcon,
                                            [
                                              _vm.getFileType(data.name) ===
                                              "image"
                                                ? _c(VIcon, [
                                                    _vm._v(
                                                      "mdi-file-image-outline"
                                                    )
                                                  ])
                                                : _vm.getFileType(data.name) ===
                                                  "pdf"
                                                ? _c(VIcon, [
                                                    _vm._v(
                                                      "mdi-file-pdf-outline"
                                                    )
                                                  ])
                                                : _c(VIcon, [
                                                    _vm._v(
                                                      "mdi-file-alert-outline"
                                                    )
                                                  ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            VListItemContent,
                                            [
                                              _c(VListItemTitle, [
                                                _vm._v(_vm._s(data.name))
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { attrs: { align: "center", justify: "center" } },
                    [
                      _vm.currentLicence.licenceStatus === "pending" ||
                      _vm.currentLicence.licenceStatus === "deactivated"
                        ? _c(
                            VBtn,
                            {
                              staticClass: "mt-5",
                              attrs: {
                                outlined: "",
                                block: "",
                                color: "success"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.modifyState("active")
                                }
                              }
                            },
                            [_vm._v("Activate")]
                          )
                        : _vm._e(),
                      _vm.currentLicence.licenceStatus === "pending"
                        ? _c(
                            VBtn,
                            {
                              staticClass: "mt-5",
                              attrs: {
                                outlined: "",
                                block: "",
                                color: "error"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.modifyState("declined")
                                }
                              }
                            },
                            [_vm._v("Decline")]
                          )
                        : _vm._e(),
                      _vm.currentLicence.licenceStatus === "active"
                        ? _c(
                            VBtn,
                            {
                              staticClass: "mt-5",
                              attrs: {
                                outlined: "",
                                block: "",
                                color: "warning"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.modifyState("deactivated")
                                }
                              }
                            },
                            [_vm._v("Deactivate")]
                          )
                        : _vm._e(),
                      _c(
                        VBtn,
                        {
                          staticClass: "mt-5",
                          attrs: { outlined: "", block: "", color: "error" },
                          on: { click: _vm.deleteLicence }
                        },
                        [_vm._v("Delete")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCol,
                { staticClass: "mt-3" },
                [
                  _c(
                    VTabs,
                    {
                      attrs: { centered: "" },
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    [
                      _c(VTabsSlider),
                      _c(VTab, { attrs: { href: "#accesses" } }, [
                        _vm._v(" Accesses ")
                      ]),
                      _c(VTab, { attrs: { href: "#seats" } }, [
                        _vm._v(" Seats ")
                      ]),
                      _c(VTab, { attrs: { href: "#usage" } }, [
                        _vm._v(" Usage statistics ")
                      ]),
                      _c(VTab, { attrs: { href: "#eventlog" } }, [
                        _vm._v(" Event log ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    VTabsItems,
                    {
                      attrs: { align: "center" },
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    [
                      _c(
                        VTabItem,
                        { attrs: { value: "accesses", align: "center" } },
                        [
                          _c(
                            VCard,
                            { staticClass: "ma-5", attrs: { outlined: "" } },
                            [
                              _c(
                                VRow,
                                { attrs: { align: "stretch" } },
                                [
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "d-flex flex-column",
                                      attrs: { cols: 4 }
                                    },
                                    [
                                      _c("access-card", {
                                        attrs: {
                                          type: "basic",
                                          accesses: _vm.inspectAccesses,
                                          licenceId: _vm.currentLicence.id
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "d-flex flex-column",
                                      attrs: { cols: 4 }
                                    },
                                    [
                                      _c("access-card", {
                                        attrs: {
                                          type: "pro",
                                          accesses: _vm.inspectAccesses,
                                          licenceId: _vm.currentLicence.id
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "d-flex flex-column",
                                      attrs: { cols: 4 }
                                    },
                                    [
                                      _c("access-card", {
                                        attrs: {
                                          type: "premium",
                                          accesses: _vm.inspectAccesses,
                                          licenceId: _vm.currentLicence.id
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VTabItem,
                        { attrs: { value: "seats" } },
                        [
                          _c(
                            VCard,
                            {
                              staticClass: "ma-5",
                              attrs: {
                                outlined: "",
                                loading: _vm.seatLoading,
                                disabled: _vm.seatLoading
                              }
                            },
                            [
                              _c(
                                VMenu,
                                {
                                  attrs: {
                                    transition: "slide-y-transition",
                                    "close-on-content-click": false,
                                    bottom: ""
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var attrs = ref.attrs
                                          var on = ref.on
                                          return [
                                            _c(
                                              VBtn,
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "ma-5",
                                                    attrs: {
                                                      color: "info",
                                                      outlined: ""
                                                    }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v("Add seat")]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1300185774
                                  )
                                },
                                [
                                  _c(
                                    VCard,
                                    [
                                      _c(
                                        VCardText,
                                        [
                                          _c(VTextField, {
                                            attrs: {
                                              outlined: "",
                                              dense: "",
                                              "prepend-icon": "mdi-tag-outline",
                                              label: "Seat name"
                                            },
                                            model: {
                                              value: _vm.newSeatName,
                                              callback: function($$v) {
                                                _vm.newSeatName = $$v
                                              },
                                              expression: "newSeatName"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCardActions,
                                        [
                                          _c(VSpacer),
                                          _c(
                                            VBtn,
                                            {
                                              attrs: {
                                                outlined: "",
                                                color: "info"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.addSeat()
                                                }
                                              }
                                            },
                                            [_vm._v("Add")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                VList,
                                {
                                  attrs: {
                                    justify: "start",
                                    align: "left",
                                    "max-width": "600"
                                  }
                                },
                                [
                                  _vm._l(_vm.inspectSeats, function(
                                    seat,
                                    index
                                  ) {
                                    return [
                                      index > 0
                                        ? _c(VDivider, { key: index })
                                        : _vm._e(),
                                      _c(
                                        VListGroup,
                                        {
                                          key: seat.name,
                                          attrs: { value: false },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      VListItem,
                                                      [
                                                        _c(
                                                          VListItemIcon,
                                                          [
                                                            _c(
                                                              VMenu,
                                                              {
                                                                attrs: {
                                                                  transition:
                                                                    "slide-y-transition",
                                                                  "close-on-content-click": false,
                                                                  bottom: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var attrs =
                                                                          ref.attrs
                                                                        var on =
                                                                          ref.on
                                                                        return [
                                                                          _c(
                                                                            VHover,
                                                                            {
                                                                              scopedSlots: _vm._u(
                                                                                [
                                                                                  {
                                                                                    key:
                                                                                      "default",
                                                                                    fn: function(
                                                                                      ref
                                                                                    ) {
                                                                                      var hover =
                                                                                        ref.hover
                                                                                      return [
                                                                                        _c(
                                                                                          VChip,
                                                                                          _vm._g(
                                                                                            _vm._b(
                                                                                              {
                                                                                                attrs: {
                                                                                                  color: _vm.getColor(
                                                                                                    seat.name
                                                                                                  )
                                                                                                }
                                                                                              },
                                                                                              "v-chip",
                                                                                              attrs,
                                                                                              false
                                                                                            ),
                                                                                            on
                                                                                          ),
                                                                                          [
                                                                                            _vm._v(
                                                                                              " " +
                                                                                                _vm._s(
                                                                                                  seat.name
                                                                                                ) +
                                                                                                " "
                                                                                            ),
                                                                                            hover
                                                                                              ? _c(
                                                                                                  VIcon,
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "ml-2",
                                                                                                    attrs: {
                                                                                                      small:
                                                                                                        ""
                                                                                                    }
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "mdi-pencil"
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              : _vm._e()
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      ]
                                                                                    }
                                                                                  }
                                                                                ],
                                                                                null,
                                                                                true
                                                                              )
                                                                            }
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _c(
                                                                  VCard,
                                                                  [
                                                                    _c(
                                                                      VCardText,
                                                                      [
                                                                        _c(
                                                                          VTextField,
                                                                          {
                                                                            attrs: {
                                                                              outlined:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              "prepend-icon":
                                                                                "mdi-tag",
                                                                              label:
                                                                                "New seat name"
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                _vm.updateSeatName,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.updateSeatName = $$v
                                                                              },
                                                                              expression:
                                                                                "updateSeatName"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      VCardActions,
                                                                      [
                                                                        _c(
                                                                          VSpacer
                                                                        ),
                                                                        _c(
                                                                          VBtn,
                                                                          {
                                                                            attrs: {
                                                                              color:
                                                                                "info",
                                                                              outlined:
                                                                                ""
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.seatNameUpdate(
                                                                                  seat
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "Update"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          VListItemContent,
                                                          [
                                                            _c(
                                                              VListItemTitle,
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      seat
                                                                        .accessIds
                                                                        .length
                                                                    ) +
                                                                    " user "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          VListItemAction,
                                                          [
                                                            _c(
                                                              VBtn,
                                                              {
                                                                attrs: {
                                                                  icon: "",
                                                                  color: "error"
                                                                },
                                                                nativeOn: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.deleteSeat(
                                                                      seat
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c(VIcon, [
                                                                  _vm._v(
                                                                    "mdi-delete-outline"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            VListItem,
                                            {
                                              attrs: {
                                                "align-ceter": "",
                                                "justify-center": ""
                                              }
                                            },
                                            [
                                              _c(
                                                VListItemAction,
                                                [
                                                  _c(
                                                    VMenu,
                                                    {
                                                      attrs: {
                                                        transition:
                                                          "slide-y-transition",
                                                        "close-on-content-click": false,
                                                        bottom: ""
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var attrs =
                                                                ref.attrs
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  VBtn,
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        attrs: {
                                                                          outlined:
                                                                            "",
                                                                          color:
                                                                            "info",
                                                                          small:
                                                                            ""
                                                                        }
                                                                      },
                                                                      "v-btn",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      VIcon,
                                                                      {
                                                                        staticClass:
                                                                          "mr-3"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-account-plus"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " Add"
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _c(
                                                        VCard,
                                                        [
                                                          _c(
                                                            VCardText,
                                                            [
                                                              _c(
                                                                VAutocomplete,
                                                                {
                                                                  attrs: {
                                                                    dense: "",
                                                                    outlined:
                                                                      "",
                                                                    "prepend-icon":
                                                                      "mdi-email-outline",
                                                                    label:
                                                                      "Users",
                                                                    items:
                                                                      _vm.inspectNonEmptyAccesses,
                                                                    "item-text":
                                                                      "email",
                                                                    "item-value":
                                                                      "id"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.addToSeatAccessId,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.addToSeatAccessId = $$v
                                                                    },
                                                                    expression:
                                                                      "addToSeatAccessId"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            VCardActions,
                                                            [
                                                              _c(VSpacer),
                                                              _c(
                                                                VBtn,
                                                                {
                                                                  attrs: {
                                                                    outlined:
                                                                      "",
                                                                    color:
                                                                      "info",
                                                                    disabled:
                                                                      _vm.addToSeatAccessId ===
                                                                      null
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.addUserToSeat(
                                                                        seat
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [_vm._v("Add")]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._l(seat.accessIds, function(
                                            accId,
                                            index
                                          ) {
                                            return [
                                              index > 0
                                                ? _c(VDivider, {
                                                    key: index,
                                                    attrs: { inset: "" }
                                                  })
                                                : _vm._e(),
                                              _c(
                                                VListItem,
                                                {
                                                  key: accId,
                                                  staticClass:
                                                    "ml-10 justify-start",
                                                  attrs: { dense: "" }
                                                },
                                                [
                                                  _c(
                                                    VListItemAvatar,
                                                    [
                                                      _c(
                                                        VAvatar,
                                                        {
                                                          attrs: {
                                                            color: _vm.getColor(
                                                              _vm.getUserEmail(
                                                                accId
                                                              )
                                                            )
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.nameInitials(
                                                                _vm.getUserName(
                                                                  accId
                                                                )
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    VListItemContent,
                                                    [
                                                      _c(VListItemTitle, [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.getUserName(
                                                                accId
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]),
                                                      _c(
                                                        VListItemSubtitle,
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getUserEmail(
                                                                  accId
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    VListItemAction,
                                                    [
                                                      _c(
                                                        VBtn,
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            color: "error"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.removeUserFromSeat(
                                                                _vm.inspectAccesses.find(
                                                                  function(
                                                                    acc
                                                                  ) {
                                                                    return (
                                                                      acc.id ===
                                                                      accId
                                                                    )
                                                                  }
                                                                ),
                                                                seat
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(VIcon, [
                                                            _vm._v(
                                                              "mdi-delete-outline"
                                                            )
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          })
                                        ],
                                        2
                                      )
                                    ]
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VTabItem,
                        { attrs: { value: "usage" } },
                        [
                          _c(
                            VBtn,
                            {
                              staticClass: "ma-5",
                              attrs: {
                                outlined: "",
                                loading: _vm.fetchStatisticsLoader,
                                disabled: _vm.fetchStatisticsLoader,
                                color: "primary"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.fetchLicenceSessions()
                                }
                              }
                            },
                            [_vm._v(" Load statistics ")]
                          ),
                          _c(
                            VCard,
                            { staticClass: "ma-5", attrs: { outlined: "" } },
                            [
                              _vm.licenceSessions.length > 0
                                ? _c(
                                    VCardText,
                                    [
                                      _c(
                                        VRow,
                                        [
                                          _c(
                                            VCol,
                                            { attrs: { md: 6 } },
                                            [
                                              _c(
                                                VList,
                                                [
                                                  _c(
                                                    VListItem,
                                                    [
                                                      _c(
                                                        VListItemIcon,
                                                        [
                                                          _c(VIcon, [
                                                            _vm._v(
                                                              "mdi-clock-outline"
                                                            )
                                                          ])
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        VListItemContent,
                                                        [
                                                          _c(
                                                            VListItemTitle,
                                                            [
                                                              _vm._v(
                                                                "Total usage: " +
                                                                  _vm._s(
                                                                    Math.floor(
                                                                      _vm
                                                                        .licenceSessionSummary
                                                                        .totalTime /
                                                                        60
                                                                    )
                                                                  ) +
                                                                  "h " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .licenceSessionSummary
                                                                      .totalTime %
                                                                      60
                                                                  ) +
                                                                  "m"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    VListItem,
                                                    [
                                                      _c(
                                                        VListItemIcon,
                                                        [
                                                          _c(VIcon, [
                                                            _vm._v(
                                                              "mdi-history"
                                                            )
                                                          ])
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        VListItemContent,
                                                        [
                                                          _c(
                                                            VListItemTitle,
                                                            [
                                                              _vm._v(
                                                                "Last used: " +
                                                                  _vm._s(
                                                                    new Date(
                                                                      _vm.licenceSessionSummary.lastUsed
                                                                    ).toLocaleString()
                                                                  )
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(VCol, { attrs: { md: 6 } })
                                        ],
                                        1
                                      ),
                                      _c(
                                        VRow,
                                        [
                                          _c(
                                            VCol,
                                            { attrs: { md: 12 } },
                                            [
                                              _c("line-chart", {
                                                attrs: {
                                                  chartData:
                                                    _vm.licenceSessionSummary
                                                      .chartByDayData,
                                                  labels:
                                                    _vm.licenceSessionSummary
                                                      .chartByDayLabels,
                                                  height: "150px",
                                                  scales:
                                                    _vm.licenceSessionSummary
                                                      .chartByDayScales,
                                                  legendDisplay: true
                                                }
                                              }),
                                              _c("line-chart", {
                                                attrs: {
                                                  chartData:
                                                    _vm.licenceSessionSummary
                                                      .chartByHourData,
                                                  labels:
                                                    _vm.licenceSessionSummary
                                                      .chartByHoursLabels,
                                                  height: "150px",
                                                  legendDisplay: true
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _c(VCardText, [
                                    _c("p", [
                                      _vm._v(" No session data loaded! ")
                                    ])
                                  ]),
                              _c(VCardActions)
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }