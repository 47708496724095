
























































































































































































































































































































































































































































































import AddLicenceModal from "@/components/AddLicenceModal.vue";
import AddMembershipModal from "@/components/AddMembershipModal.vue";
//import LicenceCard from "@/components/LicenceCard.vue";
//import MembershipCard from "@/components/MembershipCard.vue";
import User from "@/types/User";
import Vue from "vue";
import HashColor from "@/plugins/hashcolor";

export default Vue.extend({
  components: {
    /*LicenceCard, MembershipCard*/ AddLicenceModal,
    AddMembershipModal,
  },
  name: "App",
  data: () => ({
    tab: null,
  }),

  computed: {
    inspectUser: function(): User {
      return this.$store.getters.inspectUser;
    },

    inspectAuth() {
      return this.$store.getters.inspectAuth;
    },

    emailVerified() {
      return true;
    },

    inspectUserInitial() {
      if (this.$store.getters.inspectUser) {
        const userName: string = this.$store.getters.inspectUser.name;
        const array = userName.split(" ");
        let initials = "";

        array.forEach((element) => (initials += element.charAt(0)));

        if (initials.length === 0) {
          return "?";
        } else {
          return initials;
        }
      } else {
        return "";
      }
    },

    licences: function() {
      return this.$store.getters.userLicences;
    },

    memberships: function() {
      return this.$store.getters.userMemberships;
    },

    isThereUser: function() {
      return (
        this.$store.getters.inspectUser !== null &&
        this.$store.getters.inspectUser !== undefined
      );
    },

    isCollectData: function() {
      return this.$store.getters.inspectUser.isCollectData;
    },

    isNewsletter: function() {
      return this.$store.getters.inspectUser.isNewsletter;
    },
  },

  methods: {
    getColor(what: string) {
      return HashColor.get(what);
    },

    capitalizeStr(str: string) {
      return str.charAt(0).toLocaleUpperCase() + str.slice(1);
    },

    isExpired(date: number) {
      return date < Date.now();
    },

    isExpireSoon(date: number) {
      const diff = date - Date.now();

      return diff > 0 && diff < 7 * 24 * 60 * 60 * 1000;
    },
  },
  watch: {
    $route(to, from) {
      if (to.params.userId !== from.params.userId) {
        this.$store.dispatch("fetchInspectUser", this.$route.params.userId);
        this.$store.dispatch("fetchUserLicences", this.$route.params.userId);
        this.$store.dispatch("fetchUserMemberships", this.$route.params.userId);
      }
    },
  },

  created() {
    this.$store.dispatch("fetchInspectUser", this.$route.params.userId);
    this.$store.dispatch("fetchUserLicences", this.$route.params.userId);
    this.$store.dispatch("fetchUserMemberships", this.$route.params.userId);
  },
});
